import React from 'react';

function TermsOfUse() {
  return (
    <div className="container section-padding">
      <div className="section-title-wrapper">
        {/* {postData?.length > 0 && ( */}
        <div className="section-title">
          Terms of use <span className="gradient-text">Policy </span>
        </div>
        {/* )} */}
      </div>
      <div className="border border-1 rounded-4 container">
        <div className="mt-3">
          <div className="">
            Please read these Terms and Conditions (“Terms”, “Terms and
            Conditions”) carefully before using the{' '}
            <span
              className="fw_800 cursor-pointer"
              onClick={() =>
                window.open('https://www.unicornui.com/', '_Blank')
              }
            >
              https://www.unicornui.com/
            </span>{' '}
            website (the “Service”). Using any services or product of this
            website you acknowledge that you have read and agreed to the
            following terms of use, privacy policy as well as the specific terms
            of use pertaining to the deal that you are purchasing{' '}
          </div>
          <div>
            If you disagree with any part of the terms then you may not access
            the Service.
          </div>
        </div>
        <div className="mt-5">
          <h6>Privacy Policy</h6>
          <div>
            Unicorn UI is committed to protecting your privacy. Please refer to
            our <span className="gradient-text"> Privacy policy </span>for
            information on how we collect, use and, disclose personal
            information.
          </div>
        </div>
        <div className="mt-5">
          <h6>Ownership of the site and assets</h6>
          <div>
            The site includes all digital assets you see such as templates,
            illustrations, icons, UI kits, fonts, and anything owned by Unicorn
            UI, or its licensors.
          </div>
          <div>
            All materials contained on the Services fall under our Copyright.
            All rights reserved. No person is authorized to use, copy or
            distribute any portion of the Services including related graphics.
          </div>
          <div>
            Also, we are authorized to take legal action against any person who
            uses our assets without taking our permission or giving credit to
            us.
          </div>
          <div>
            We also appreciate any feedback or other suggestions about our
            services that you may submit to us
          </div>
          {/* <div className="mt-1 data-type">Types of Data Collected</div>
          <div className="mt-3">
            <div className="gradient-text fw_800 border-gradient">
              Personal Data
            </div>
            <div className="mt-1">
              While using our Service, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you (“Personal Data”). Personally identifiable
              information may include, but is not limited to:
            </div>
          </div>
          <div className="mt-3">
            <div className="gradient-text fw_800 border-gradient">
              Email address
            </div>
            <div className="mt-1">First name and last name of users</div>
          </div>
          <div className="mt-3">
            <div className="gradient-text fw_800 border-gradient">
              Cookies and Usage Data
            </div>
            <div className="mt-1">
              We may use your Personal Data to contact you with newsletters,
              marketing or promotional materials and other information that may
              be of interest to you. You may opt out of receiving any, or all,
              of these communications from us by following the unsubscribe link
              or the instructions provided in any email we send.
            </div>
          </div>
          <div className="mt-3">
            <div className="gradient-text fw_800 border-gradient">
              Usage Data
            </div>
            <div className="mt-1">
              We may also collect information on how the Service is accessed and
              used (“Usage Data”). This Usage Data may include information such
              as your computer’s Internet Protocol address (e.g. IP address),
              browser type, browser version, the pages of our Service that you
              visit, the time and date of your visit, the time spent on those
              pages, unique device identifiers and other diagnostic data.
            </div>
          </div>
          <div className="mt-3">
            <div className="gradient-text fw_800 border-gradient">
              Tracking & Cookies Data
            </div>
            <div className="mt-1">
              We use cookies and similar tracking technologies to track the
              activity on our Service and we hold certain information.
            </div>
            <div className="mt-1">
              Cookies are files with a small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a website and stored on your device. Other tracking technologies
              are also used such as beacons, tags and scripts to collect and
              track information and to improve and analyse our Service.
            </div>
            <div className="mt-1">
              You can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.
            </div>
          </div>
          <div className="mt-3">
            <div className="gradient-text fw_800 border-gradient">
              Examples of Cookies we use:
            </div>
            <div className="mt-1">
              <span className="fw_800 border-bottom border-dark">
                Session Cookies :
              </span>{' '}
              We use Session Cookies to operate our Service.
            </div>
            <div className="mt-1">
              <span className="fw_800 border-bottom border-dark">
                Preference Cookies :
              </span>{' '}
              We use Preference Cookies to remember your preferences and various
              settings.
            </div>
            <div className="mt-1">
              <span className="fw_800 border-bottom border-dark">
                Security Cookies :
              </span>{' '}
              We use Security Cookies for security purposes.
            </div>
          </div> */}
        </div>
        <div className="mt-5">
          <h6>Responsibility of Contributors.</h6>
          <div>
            If you operate an account, comment on a post, publish material on
            our Services, publish links on our Services, or otherwise make (or
            allow any third party to make) material available by means of the
            Services (any such material, Content), you are entirely responsible
            for the content of, and any harm resulting from, that Content. That
            is the case regardless of whether the Content in question
            constitutes text or graphics.
          </div>
          <div className="mt-1 fw_800">
            By making Content available, you represent and warrant that:
          </div>
          <ul style={{ listStyle: 'disc' }} className="ms-5">
            <li>
              the downloading, copying and use of the Content will not infringe
              the proprietary rights, including but not limited to the
              copyright, patent, trademark or trade secret rights, of any third
              party
            </li>
            <li>
              if your employer has rights to intellectual property you create,
              you have either (i) received permission from your employer to post
              or make available the Content, including but not limited to any
              software, or (ii) secured from your employer a waiver as to all
              rights in or to the Content;
            </li>
            <li>
              you have fully complied with any third-party licenses relating to
              the Content, and have done all things necessary to successfully
              pass through to end users any required terms;
            </li>
            <li>
              the Content or links do not contain or install any viruses, worms,
              malware, Trojan horses or other harmful or destructive content;
            </li>
            <li>
              the Content or links are not spam, is not machine- or
              randomly-generated, and does not contain unethical or unwanted
              commercial content designed to drive traffic to third party sites
              or boost the search engine rankings of third party sites, or to
              further unlawful acts (such as phishing) or mislead recipients as
              to the source of the material (such as spoofing);
            </li>
            <li>
              the Content or links are not obscene, libelous or defamatory,
              hateful or racially or ethnically objectionable, and does not
              violate the privacy or publicity rights of any third party
            </li>
            <li>
              your account is not getting advertised via unwanted electronic
              messages such as spam links on newsgroups, email lists, other
              blogs and web sites, and similar unsolicited promotional methods;
            </li>
            <li>
              your account is not named in a manner that misleads your readers
              into thinking that you are another person or company. For example,
              your account’s Twitter or name is not the name of a person other
              than yourself or company other than your own;
            </li>
            <li>
              you have, in the case of Content that includes computer code,
              accurately categorized and/or described the type, nature, uses and
              effects of the materials, whether requested to do so by our
              Services or otherwise.
            </li>
          </ul>
          <div>
            Unicorn UI reserves the right to remove any post, or Content
            (graphics or text) for any reason whatsoever. Unicorn-Ui reserves
            the right to ban any user from using our services for any reason.
          </div>
          <div>
            When you upload graphics to Unicorn UI you give us permission to use
            or distribute your graphics on{' '}
            <span
              className="fw_800 cursor-pointer"
              onClick={() =>
                window.open('https://www.unicornui.com/', '_Blank')
              }
            >
              https://www.unicornui.com
            </span>{' '}
            or affiliated sites under the same terms. All graphics uploaded are
            copyrighted by © their respective owners.
          </div>
          <div>
            If you delete graphics or content from the Services, Unicorn-Ui will
            use reasonable efforts to remove it from the Services, but you
            acknowledge that caching or references to the Content may not be
            made immediately unavailable.
          </div>
          <div>
            Without limiting any of those representations or warranties, Unicorn
            UI has the right (though not the obligation) to (i) refuse or remove
            any content that, in Unicorn UI’ reasonable opinion, violates any of
            our policies or is in any way harmful or objectionable, or (ii)
            terminate or deny access to and use of the Services to any
            individual or entity for any reason..
          </div>
        </div>
        <div className="mt-5">
          <h6>Cancellation Policy</h6>
          <div className="mt-1">
            Orders for digital products cannot be canceled once the payment is processed.
            Subscription-based services can be canceled at any time; however, the cancellation will take effect at the end of the current billing cycle.
          </div>
        </div>
        <div className="mt-5">
          <h6>Refund Policy</h6>
          <div className="mt-1">
            Since our products are digital and instantly accessible, we do not generally offer refunds. However, we may consider refund requests under the following conditions:
            Duplicate Payment: If you accidentally make multiple payments for the same product, we will refund the duplicate charge.
            Technical Issues: If you are unable to access or use our product due to a technical issue on our end, we will provide assistance. If the issue remains unresolved, a refund may be issued.
            Misrepresentation: If a product is significantly different from its description on our website, you may request a refund within 7 days of purchase.
          </div>
        </div>
        <div className="mt-5">
          <h6>Links to third party web site</h6>
          <div className="mt-1">
            Unicorn UI makes no claim or representation regarding and accepts no
            responsibility for, directly or indirectly, the quality, content,
            nature, or reliability of third-party websites accessible by
            hyperlink from the Site, or websites linking to the Site. Such sites
            are not under the control of Unicorn UI and we are not responsible
            for the contents of any linked site or any link contained in a
            linked site, or any review, changes, or updates to such sites.
          </div>
          <div className="mt-1 fw_800 gradient-text border-gradient">
            Limitation of Liability
          </div>
          <div>
            We are not liable for any direct, indirect, incidental, special, or
            consequential damages resulting from the use of our marketplace or
            the content available on the site.
          </div>
          <div className="mt-1 fw_800 gradient-text border-gradient">
            Premium Subscription
          </div>
          <div>
            Some designs may require a premium subscription for access. Users
            who purchase a premium subscription will have access to exclusive
            designs and additional features.
          </div>
        </div>
        <div className="mt-5">
          <h6>Children's policy</h6>
          <div className="mt-1">
            We do not knowingly collect or solicit personally identifiable
            information from children under 18; if you are a child under 18,
            please do not attempt to register for the Services or send any
            personal information about yourself to us. If we learn we have
            collected personal information from a child under 18, we will delete
            that information as quickly as possible. If you believe that a child
            under 18 may have provided us personal information, please contact
            us at <span className="fw_800">unicornuiofficial@gmail.com.</span>
          </div>
        </div>
        <div className="mt-5">
          <h6>Advertiser's policy</h6>
          <div className="mt-1">
            The Site may contain advertising and sponsorships. Advertisers and
            sponsors are responsible for ensuring that material submitted for
            inclusion on the Site is accurate and complies with applicable laws.
            We are not responsible for the illegality or any error, inaccuracy,
            or problem in the advertiser's or sponsor's materials.
          </div>
        </div>
        <div className="mt-5">
          <h6>Changes to Terms</h6>
          <div className="mt-1">
            We reserve the right to modify these Terms at any time without
            notice. Your continued use of our marketplace following any such
            changes constitutes your acceptance of the new Terms.
          </div>
        </div>
        <div className="mt-5">
          <h6>Disclaimers</h6>
          <div className="mt-1">
            Our marketplace provides designs "as is" and without warranty of any
            kind. We do not guarantee the accuracy or completeness of any
            content on our site.
          </div>
          <div className="mt-1">
            You should review the applicable terms and policies, including
            privacy and data-gathering practices, of any site to which you
            navigate from the Site.
          </div>
        </div>
        <div className="mt-5">
          <h6>Information protection</h6>
          <div className="mt-1">
            Every information you submit to our portal like your personal data
            and work is secured by us.
          </div>
          <div className="mt-1">
            All data stored on our servers is protected by a secure “firewall”
            so that no unauthorized use or activity can take place.
          </div>
          <div className="mt-1">
            Although we will make every effort to safeguard your personal
            information from loss, misuse or alteration by third parties, you
            should be aware that there is always some risk that thieves may find
            a way to thwart our security system or that transmissions over the
            Internet may in some highly unusual cases be intercepted.
          </div>
        </div>
        <div className="mt-5 mb-5">
          <h6>Contact Us</h6>
          <div className="mt-1">
            If you have any question, don't hasitate to ask us at
          </div>
          <div className="mt-1 fw_800">
            By email: unicornuiofficial@gmail.com
          </div>
        </div>
      </div>
      <ins className="adsbygoogle"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', marginBottom: "10px" }}
        data-ad-client="ca-pub-9953424002161141"
        data-ad-slot="4560984194"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
    </div>
  );
}

export default TermsOfUse;
